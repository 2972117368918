<div class="flex flex-col amm-foot gap-3">
  <div>
    <img
      class="foot-logo"
      src="../../../../assets/img/logo/logo_complete_horizontal.png"
      alt="Opps! logo is missing"
    />
  </div>
  <div class="flex flex-row justify-between">
    <small class="font-medium copy-right"
      >© 2025 AmMall Inc. All rights reserved.</small
    >
    <small class="flex flex-row gap-8">
      <div class="font-semibold">
        <a
          [routerLink]="[
            '',
            { outlets: { primary: ['help-center'], mainBody: null } },
          ]"
          >Help Center</a
        >
      </div>
      <div class="font-semibold">
        <a [routerLink]="['',{ outlets: { primary: ['legal-docs', 'PRIVACY'], mainBody: null } },]">Privacy Policy</a>
      </div>
      <div class="font-semibold">
        <a
          [routerLink]="[
            '',
            { outlets: { primary: ['legal-docs', 'TERMS'], mainBody: null } },
          ]"
          >Condition of Use</a
        >
      </div>
      <div class="font-semibold">
        <a
          [routerLink]="[
            '',
            {
              outlets: {
                primary: ['legal-docs', 'ADS_POLICY'],
                mainBody: null,
              },
            },
          ]"
          >Interest-Based Ads Policy</a
        >
      </div>
    </small>
  </div>
</div>
